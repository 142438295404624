import { Modal } from 'shared/components/modal/modal';
import { Suspense, useCallback } from 'react';
import { BonusDepositForm } from './form/bonusDepositForm';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { AdminSupportActions } from 'features/AdminSupport/model/slice/adminSupportSlice';

interface AdminSupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BonusDepositModal = (props: AdminSupportModalProps) => {
  const { isOpen, onClose } = props;
  const dispatch = useAppDispatch();

  const onSuccess = useCallback(() => {
    onClose();
    dispatch(AdminSupportActions.clearBonusDeposit());
  }, [onClose, dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onSuccess}>
      <Suspense fallback={''}>
        <BonusDepositForm onSuccess={onClose} />
      </Suspense>
    </Modal>
  );
};

export { BonusDepositModal };
