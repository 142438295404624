import { createSelector } from '@reduxjs/toolkit';
import { TariffsSchema } from '../types/triffsSchema';
import { TState } from 'store/types';

const getTariffs = (state: TState): TariffsSchema => state.tariffs;

export const getCost = createSelector(
  [
    getTariffs,
    (
      _,
      params: {
        type: string;
        grade: string[];
        count: number;
      },
    ) => params,
  ],
  ({ response: { data } }, params) => {
    if (params.grade.includes('new')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'new')?.client_rate || 0) * params.count
      );
    }
    if (params.grade.includes('spec')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'spec')?.client_rate || 0) * params.count
      );
    }
    if (params.grade.includes('profi')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'profi')?.client_rate || 0) * params.count
      );
    }
    if (params.grade.includes('expert')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'expert')?.client_rate || 0) * params.count
      );
    }
    if (params.grade.includes('grandmaster')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'grandmaster')?.client_rate || 0) *
        params.count
      );
    }
    if (params.grade.includes('superstar')) {
      return (
        (data
          .filter(tariffs => tariffs.poll_type === params.type)
          .find(res => res.grade === 'superstar')?.client_rate || 0) *
        params.count
      );
    }
    return 0;
  },
);

export const getMainPrice = createSelector(
  getTariffs,
  ({ mainTariffs }) => mainTariffs,
);

export const getTariffsPolls = createSelector(
  getTariffs,
  ({ response: { data } }) => data,
);
