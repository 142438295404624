import NetworkError from 'shared/exceptions/network';

export interface CompliantSchema {
  pollId: number | undefined;
  imageId: number | undefined;
  resultId: number | undefined;
  reason: Reason | null;
  comment?: string;
  showCompliantModal: boolean;
  compliant: {
    response: string | undefined;
    isLoading: boolean;
    error: NetworkError | null;
  };
}

export enum Reason {
  NONSENSE = 'nonsense',
  PROFANITY = 'profanity',
  OTHER = 'other',
}
