import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Layouts, LayoutValues } from 'shared/layouts/types';
import ROUTES from 'shared/constants/routes';
import NavbarFooter from 'shared/components/navbarFooter';
import { NavbarFooterSupport } from 'shared/components/navbarFooterSupport';
import { getOzonAuth, getUserResponse } from 'store/selectors/user';
import { SendMessageToRespondentsModal } from 'features/SendMessageToRespondents';
import { SocialBlock } from '../socialBlock';
import cl from 'classnames';
import style from './index.module.scss';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';

const Footer: FC<PropsWithChildren<{ layout?: LayoutValues }>> = ({
  layout,
}) => {
  const isIndex = layout === Layouts.Index;
  const paymentsIcon = ['paykeeper', 'visa', 'mastercard', 'mir', 'unionpay'];
  const { isOzonAuth, isLocalStorageOzonAuth } = useSelector(getOzonAuth);
  const { originDomIsOzon } = useIsIframe();
  const currentUser = useSelector(getUserResponse);
  const isSupport = currentUser.response?.data.support;
  const routesValues = Object.values(ROUTES);
  const footerLink = routesValues.filter(
    ({ POSITION }) => POSITION === 'footer',
  );

  return (
    <footer className={cl(style.footer, 'wrapper')}>
      <div className={style.inner}>
        {isSupport ? (
          <NavbarFooterSupport />
        ) : (
          <NavbarFooter links={footerLink} />
        )}

        {!isOzonAuth &&
          !isLocalStorageOzonAuth &&
          !originDomIsOzon &&
          !isSupport && (
            <a
              className={style.copyright}
              href="https://www.de.ru/"
              target="_blank"
            >
              Создано в Digital Evolution
            </a>
          )}

        {isIndex &&
        !isOzonAuth &&
        !isLocalStorageOzonAuth &&
        !originDomIsOzon ? (
          <>
            <SocialBlock />
            <ul className={style.paymentsList}>
              {paymentsIcon.map(name => {
                return (
                  <li className={style.item} key={name}>
                    <img src={`/img/payments/logo_${name}.svg`} alt={name} />
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}

        <SendMessageToRespondentsModal />
      </div>
    </footer>
  );
};

export default Footer;
