import { TState } from 'store/types';
import { BalanceSchema } from '../types/balanceSchema';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const getBalance = (state: TState): BalanceSchema => state.balance;

export const getDraftTransactionsList = createSelector(
  getBalance,
  ({ dataDraft }) =>
    dataDraft?.map(item => ({
      date: dayjs(item.updated_at).format('DD.MM.YYYY'),
      descr: item.type,
      sum: {
        value: Math.abs(+item.sum),
        status: +item.sum > 0 ? 'error' : undefined,
      },
      document: 'download',
      id: item.id,
    })),
);

export const getDocumentIcon = (process: boolean | null, legal?: boolean) => {
  if (process === null) {
    return '';
  }
  if (process === false) {
    if (legal) {
      return 'act';
    } else {
      return '';
    }
  }
  if (process === true) {
    if (legal) {
      return 'wait';
    } else {
      return '';
    }
  }
  return '';
};

export const getExecutedTransactionsList = createSelector(
  getBalance,
  ({ dataExecuted }) =>
    dataExecuted?.map(item => ({
      date: dayjs(item.updated_at).format('DD.MM.YYYY'),
      descr: item.type,
      sum: {
        value: Math.abs(+item.sum),
        status: +item.sum > 0 ? 'success' : undefined,
      },
      document: getDocumentIcon(item.poll_process, item.legal),
      balance: +item.balance,
      id: item.id,
    })),
);

export const getDraftTransactionsLoading = createSelector(
  getBalance,
  ({ loadingDraft }) => loadingDraft,
);

export const getExecutedTransactionsLoading = createSelector(
  getBalance,
  ({ loadingExecuted }) => loadingExecuted,
);

export const getDraftTransactionsError = createSelector(
  getBalance,
  ({ errorDraft }) => errorDraft,
);

export const getExecutedTransactionsError = createSelector(
  getBalance,
  ({ errorExecuted }) => errorExecuted,
);

export const getBalancePage = createSelector(getBalance, ({ page }) => page);

export const getBalanceTotal = createSelector(getBalance, ({ total }) => total);

export const getBalanceLastPage = createSelector(
  getBalance,
  ({ lastPage }) => lastPage,
);

export const getIsEmptyBalancsPage = createSelector(
  getBalance,
  ({
    dataDraft,
    dataExecuted,
    loadingDraft,
    loadingExecuted,
    errorDraft,
    errorExecuted,
  }) => {
    return (
      !dataDraft?.length &&
      !dataExecuted?.length &&
      !loadingDraft &&
      !loadingExecuted &&
      !errorDraft &&
      !errorExecuted
    );
  },
);

export const getBalancePageIsLoading = createSelector(
  getBalance,
  ({ loadingDraft, loadingExecuted, initDraftBlock, initPaymentsBlock }) => {
    return (
      (loadingDraft && loadingExecuted) ||
      (!initDraftBlock && loadingDraft) ||
      (!initPaymentsBlock && loadingExecuted)
    );
  },
);

export const getInitBalancePage = createSelector(
  getBalance,
  ({ initDraftBlock, initPaymentsBlock }) => {
    return initDraftBlock && initPaymentsBlock;
  },
);

export const getInitBalanceDraftBlock = createSelector(
  getBalance,
  ({ initDraftBlock }) => {
    return initDraftBlock || false;
  },
);

export const getInitBalancePaymentsBlock = createSelector(
  getBalance,
  ({ initPaymentsBlock }) => {
    return initPaymentsBlock || false;
  },
);

export const getDownloadIsLoading = createSelector(
  [getBalance, (_, id: number) => id],
  ({ downloadDocument: { loading, idDocument } }, id) => {
    if (id && id === idDocument && loading) {
      return true;
    } else return false;
  },
);

export const getDownloadError = createSelector(
  [getBalance, (_, id: number) => id],
  ({ downloadDocument: { error, idDocument } }, id) => {
    if (id && id === idDocument && error) {
      return error;
    } else return null;
  },
);

export const getDownloadIsDownloaded = createSelector(
  [getBalance, (_, id: number) => id],
  ({ downloadDocument: { isDownloaded, idDocument } }, id) => {
    if (id && id === idDocument && isDownloaded) {
      return true;
    } else return false;
  },
);

export const getPayerUsername = createSelector(
  getBalance,
  ({ upBalance: { username } }) => {
    return username;
  },
);

export const getPayerEmail = createSelector(
  getBalance,
  ({ upBalance: { email } }) => {
    return email;
  },
);

export const getPayerCompanyAddress = createSelector(
  getBalance,
  ({ upBalance: { address } }) => {
    return address;
  },
);

export const getPayerCompanyLabel = createSelector(
  getBalance,
  ({ upBalance: { companyLabel } }) => {
    return companyLabel;
  },
);

export const getPayerCompanyname = createSelector(
  getBalance,
  ({ upBalance: { companyname } }) => {
    return companyname;
  },
);
export const getPayerInn = createSelector(
  getBalance,
  ({ upBalance: { inn } }) => {
    return inn;
  },
);
export const getPayerPayerType = createSelector(
  getBalance,
  ({ upBalance: { payer } }) => {
    return payer;
  },
);

export const getTransactionType = createSelector(
  getBalance,
  ({ upBalance: { transactionType } }) => {
    return transactionType;
  },
);

export const getPayerSumme = createSelector(
  getBalance,
  ({ upBalance: { summ } }) => {
    return summ;
  },
);

export const getCanCreatePaymentInvoice = createSelector(
  getBalance,
  ({ upBalance }) => {
    if (upBalance.summ) {
      if (upBalance.payer === 'company' && upBalance.companyname) {
        return true;
      }
      if (upBalance.payer === 'user' && upBalance.username) {
        return true;
      }
    }
    return false;
  },
);

export const getBalancePopupIsOpen = createSelector(
  getBalance,
  ({ upBalance: { popupIsOpen } }) => {
    return popupIsOpen;
  },
);

export const getOzonBalancePopupIsOpen = createSelector(
  getBalance,
  ({ upBalance: { ozonPopupIsOpen } }) => {
    return ozonPopupIsOpen;
  },
);

export const getBalancePopupIsLoading = createSelector(
  getBalance,
  ({ upBalance: { loading } }) => {
    return loading;
  },
);
export const getInvoiceLoading = createSelector(
  getBalance,
  ({ upBalance: { downloadLoading } }) => {
    return downloadLoading;
  },
);

export const getBalancePopupError = createSelector(
  getBalance,
  ({ upBalance: { error } }) => {
    return error;
  },
);

export const getBalanceRedirectLink = createSelector(
  getBalance,
  ({ upBalance: { redirectLink } }) => {
    return redirectLink;
  },
);

export const getBalanceRedirectBalance = createSelector(
  getBalance,
  ({ upBalance: { redirectToBalance } }) => {
    return redirectToBalance || false;
  },
);
