import { TPollsState } from './types';

const initialState: TPollsState = {
  polls: {
    response: null,
    error: null,
    loading: false,
  },
  editPoll: {
    response: null,
    error: null,
    loading: false,
  },
  targeting: {
    questions: [],
    loading: false,
  },
  filters: {
    response: null,
    error: null,
    loading: false,
  },
  newPoll: {
    response: null,
    error: null,
    loading: false,
  },
  pollReport: {
    response: null,
    error: null,
    loading: false,
  },
  downloadPollReport: {
    loading: false,
  },
  addPoll: {
    added: false,
    loading: false,
  },
};

export default initialState;
