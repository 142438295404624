import decode from 'jwt-decode';
import { AUTH_TOKEN, IS_OZON_USER } from 'shared/constants/commmon';

export const getAuthUserData = (): string | null => {
  const data = localStorage.getItem(AUTH_TOKEN);
  return data ? JSON.parse(data) : null;
};

export const setAuthUserData = (data: string): void => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));
};

export const setAuthOzonUserData = (data: string): void => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));
  localStorage.setItem(IS_OZON_USER, '1');
};

export const getAuthOzonUserData = (): string | null => {
  const data = localStorage.getItem(IS_OZON_USER);
  return data ? JSON.parse(data) : null;
};

export const setAuthBitrixUserData = (data: string): void => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));
};

export const removeAuthUserData = (): void => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const checkTokenExpire = (token: string): boolean => {
  try {
    const decodeToken = decode(token) as { exp: number };
    const { exp } = decodeToken;
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > exp) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
