import { NavLink } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import style from '../navbarFooter/index.module.scss';
import cl from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { AdminSupportModal } from 'features/AdminSupport';
import { sendMessageToRespondentsActions } from 'features/SendMessageToRespondents/model/slices/sendMessageToRespondents';
import { RespondentSupportModal } from 'features/AdminSupport/ui/respondentsSupportModal/respondentsSupportModal';
import { BonusDepositModal } from 'features/AdminSupport/ui/bonusDepositModal/bonusDepositModal';

export const NavbarFooterSupport: FC = () => {
  const dispatch = useAppDispatch();
  const [isAdminSupportOpen, setIsAdminSuportOpen] = useState(false);
  const [isBonusDepositOpen, setIsBonusDepositOpen] = useState(false);
  const [isRespondentSupportOpen, setIsRespondentSuportOpen] = useState(false);
  //CLIENTS
  const onOpenAdminSupport = useCallback(() => {
    setIsAdminSuportOpen(true);
  }, []);

  const onAdminSupportClose = useCallback(() => {
    setIsAdminSuportOpen(false);
  }, []);

  //RESPONDENTS
  const onOpenRespondentSupport = useCallback(() => {
    setIsRespondentSuportOpen(true);
  }, []);
  const onRespondentSupportClose = useCallback(() => {
    setIsRespondentSuportOpen(false);
  }, []);

  //BONUS DEPOSIT
  const onBonusDepositOpen = useCallback(() => {
    setIsBonusDepositOpen(true);
  }, []);
  const onBonusDepositClose = useCallback(() => {
    setIsBonusDepositOpen(false);
  }, []);

  const onOpenSendMessageToRespondents = useCallback(() => {
    dispatch(sendMessageToRespondentsActions.sendIsopenModal(true));
  }, [dispatch]);

  return (
    <>
      <nav className={cl(style.navbarFooter)}>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onOpenAdminSupport}
        >
          {ROUTES.CONNECT.NAME}
        </NavLink>
        <NavLink to={'#'} className={cl(style.link)} onClick={() => {}}>
          {ROUTES.NOTIFICATION.NAME}
        </NavLink>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onBonusDepositOpen}
        >
          {ROUTES.BONUS_DEPOSIT.NAME}
        </NavLink>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onOpenRespondentSupport}
        >
          {ROUTES.RESPONDENT.NAME}
        </NavLink>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onOpenSendMessageToRespondents}
        >
          {ROUTES.SPAM.NAME}
        </NavLink>
        <NavLink to={ROUTES.PAYMENTS.ROUTE} className={cl(style.link)}>
          {ROUTES.PAYMENTS.NAME}
        </NavLink>
        <NavLink to={ROUTES.TROUBLE_POLLS.ROUTE} className={cl(style.link)}>
          {ROUTES.TROUBLE_POLLS.NAME}
        </NavLink>
      </nav>
      <AdminSupportModal
        isOpen={isAdminSupportOpen}
        onClose={onAdminSupportClose}
      />
      <RespondentSupportModal
        isOpen={isRespondentSupportOpen}
        onClose={onRespondentSupportClose}
      />
      <BonusDepositModal
        isOpen={isBonusDepositOpen}
        onClose={onBonusDepositClose}
      />
    </>
  );
};
