import { useMemo } from 'react';
import { useLocation } from 'react-router';
import CONFIG from 'shared/constants/config';

export const useIsIframe = () => {
  const { search, pathname } = useLocation();

  const originDoom = useMemo(() => {
    return [...new Set(self.location?.ancestorOrigins)];
  }, []);

  const isBitrixLogin = useMemo(() => {
    return (
      pathname.split('/').includes('bitrix') &&
      pathname.split('/').includes('login') &&
      pathname.split('/').includes('embed')
    );
  }, [pathname]);
  const isBitrixNew = useMemo(() => {
    return (
      pathname.split('/').includes('bitrix') &&
      pathname.split('/').includes('new') &&
      pathname.split('/').includes('embed')
    );
  }, [pathname]);
  const isBitrixAll = useMemo(() => {
    return (
      pathname.split('/').includes('bitrix') &&
      pathname.split('/').includes('all') &&
      pathname.split('/').includes('embed')
    );
  }, [pathname]);

  const isOzon = useMemo(() => {
    return (
      pathname.split('/').includes('ozon') &&
      pathname.split('/').includes('embed') &&
      pathname.split('/').includes('new')
    );
  }, [pathname]);

  const topService = useMemo(() => {
    if (!!originDoom && originDoom.length) {
      return 'isFrame';
    }
    return 'isTop';
  }, [originDoom]);

  const originDomIsOzon = useMemo(() => {
    if (!!originDoom && originDoom.length) {
      return !!originDoom.filter(or =>
        or.includes(CONFIG.IS_DEV ? '3000' : 'ozon'),
      ).length;
    }
    return false;
  }, [originDoom]);

  const name = useMemo(() => {
    return new URLSearchParams(search).get('name')?.trim();
  }, [search]);
  const phone = useMemo(() => {
    return new URLSearchParams(search).get('phone')?.trim();
  }, [search]);
  const data_token = useMemo(() => {
    return new URLSearchParams(search).get('data_token')?.trim();
  }, [search]);

  return {
    topService,
    name,
    phone,
    data_token,
    isBitrixLogin,
    isBitrixNew,
    isBitrixAll,
    isOzon,
    originDomIsOzon,
  };
};
