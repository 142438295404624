import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
import { TRespondentTransactionsRequest } from '../../types/adminSupportSchema';

export const getRespondentComplaints = createAsyncThunk(
  'support/getRespondentComplaints',
  async (data: TRespondentTransactionsRequest, thunkApi) => {
    try {
      const response = await supportModel.getRespondentsComplaints(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
