import Content from 'shared/components/grid/content';
import Col from 'shared/components/grid/col';
import { PaymentTable, TBalanceData } from '../paymentTable';
import styles from './index.module.scss';
import Pagination from 'shared/components/pagination';
import { useSelector } from 'react-redux';
import {
  getBalanceLastPage,
  getBalanceTotal,
  getDraftTransactionsError,
  getDraftTransactionsList,
  getDraftTransactionsLoading,
  getExecutedTransactionsError,
  getExecutedTransactionsList,
  getExecutedTransactionsLoading,
} from '../../../model/selectors/getBalance';
import CONFIG from 'shared/constants/config';
import { useLocation } from 'react-router';
import ROUTES from 'shared/constants/routes';
import { useRedirectOverPagination } from 'shared/helpers/hooks/useRedirectOverPagination/useRedirectOverPagination';
import Spiner from 'shared/components/loader/spinner';

const tableDraftCellTitle = [
  { id: 'date', name: 'Дата' },
  { id: 'descr', name: 'Операция' },
  { id: 'sum', name: 'Сумма' },
  { id: 'doc', name: 'Документы' },
];
const tablePaymentsCellTitle = [
  ...tableDraftCellTitle,
  { id: 'balance', name: 'Баланс' },
];

export const PaymentsList = () => {
  const { search } = useLocation();
  const page = new URLSearchParams(search).get('page') || '1';
  const draftList = useSelector(getDraftTransactionsList) as TBalanceData[];
  const draftLoading = useSelector(getDraftTransactionsLoading);
  const draftError = useSelector(getDraftTransactionsError);
  const executedList = useSelector(
    getExecutedTransactionsList,
  ) as TBalanceData[];
  const executedLoading = useSelector(getExecutedTransactionsLoading);
  const executedError = useSelector(getExecutedTransactionsError);
  const total = useSelector(getBalanceTotal);
  const lastPage = useSelector(getBalanceLastPage);

  useRedirectOverPagination(
    lastPage?.toString(),
    page,
    ROUTES.CABINET_BALANCE.ROUTE,
  );

  return (
    <Content>
      <Col left>
        <div className={styles.item}>
          {(draftList && draftList.length) || draftError ? (
            <h2 className={styles.title}>Неоплаченные счета</h2>
          ) : null}
          {/* {draftLoading && !draftList.length ? (
            <Spiner />
          ) : ( */}
          <>
            {draftList && draftList.length ? (
              <PaymentTable
                thCells={tableDraftCellTitle}
                data={draftList || []}
                loading={draftLoading}
              />
            ) : null}
          </>
          {/* )} */}
          {draftError && <pre>{draftError.message}</pre>}
        </div>
        <div className={styles.item}>
          {(executedList && executedList.length) || executedError ? (
            <h2 className={styles.title}>История операций</h2>
          ) : null}
          {executedLoading && !executedList.length ? (
            <Spiner />
          ) : (
            <>
              {executedList && executedList.length ? (
                <PaymentTable
                  className={styles.table}
                  thCells={tablePaymentsCellTitle}
                  data={executedList || []}
                  loading={executedLoading}
                />
              ) : null}
            </>
          )}
          {executedError && <pre>{executedError.message}</pre>}
          <Pagination
            total={total || 1}
            limit={CONFIG.PAGINATION_LIMIT}
            page={page}
          />
        </div>
      </Col>
    </Content>
  );
};
