import { memo } from 'react';
import './index.scss';

export const AlertBlock = memo(() => {
  return (
    <div className="alert">
      <div className="alert__wrapper wrapper">
        <div className="alert__link">
          Вы подключены к кабинету клиента. Необходимо выйти после завершения
          действий
        </div>
      </div>
    </div>
  );
});
