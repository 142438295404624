import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
import { TTroublePollsRequest } from '../../types/adminSupportSchema';

export const getTroublePollsNotApproved = createAsyncThunk(
  'support/getTroublePollsNotApproved',
  async (data: TTroublePollsRequest, thunkApi) => {
    try {
      const response = await supportModel.getTroublePolls(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const getTroublePollsProlonged = createAsyncThunk(
  'support/getTroublePollsProlonged',
  async (data: TTroublePollsRequest, thunkApi) => {
    try {
      const response = await supportModel.getTroublePolls(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
