import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { ParsedQuery } from 'query-string';
import { Reason } from 'features/ComplaintToComment/model/types/compliantSchema';
import { TCompositePollChapter } from 'pages/cabinet/store/types';

export type TPollsRequestGet = {
  limit?: number;
  page?: number;
  sort?: { column: string; direction: string };
  filters?: ParsedQuery<string> | undefined;
};

export interface IPollsRequest {
  type: string;
  start?: string;
  finish?: string;
  gender?: string;
  range?: string[];
  respondents?: number;
  draft?: boolean;
  education?: string[];
  grade?: string[];
  question?: string;
  force?: boolean;
  repeat?: boolean;
}

export interface IPollsRequestPut extends IPollsRequest {
  poll: number;
  images: (File | string)[];
}

export interface IPollsRequestPost extends IPollsRequest {
  images: (File | string)[];
}

export interface ICompositePollsRequestPost extends IPollsRequest {
  composite: TCompositePollChapter[];
  composite_theme: string;
}

function getExtensionFromMimeType(mimeType: string): string {
  const mimeToExtension: { [key: string]: string } = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    // Добавьте другие MIME-типы при необходимости
  };

  return mimeToExtension[mimeType] || 'bin'; // По умолчанию 'bin', если тип неизвестен
}

// Функция для преобразования ссылки в File
export async function urlToFile(
  imageUrl: string,
  fileName: string,
): Promise<File> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error(`Ошибка загрузки изображения: ${response.statusText}`);
  }
  const contentType = response.headers.get('Content-Type');
  if (!contentType) {
    throw new Error('Не удалось определить MIME-тип изображения');
  }

  // Определяем расширение файла на основе MIME-типа
  const extension = getExtensionFromMimeType(contentType);

  // Преобразуем ответ в Blob
  const blob = await response.blob();

  // Создаём File с правильным расширением
  return new File([blob], `${fileName}.${extension}`, { type: contentType });
}

class PollsModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка опросов
  async getPolls(data: TPollsRequestGet): Promise<TAxios> {
    return this.send('get', `/polls`, {
      params: { ...data, filters: data.filters },
    });
  }

  //Получение списка фильтров
  async getFilters(data: {
    filters?: ParsedQuery<string> | undefined;
  }): Promise<TAxios> {
    return this.send('get', `/polls/filters`, {
      params: { ...data },
    });
  }

  //Просмотр опроса
  async getPoll(id: string): Promise<TAxios> {
    return this.send('get', `/polls/${id}`);
  }

  //Подробный отчет по отпросу
  async getPollReport(data: {
    id: string;
    filters?: ParsedQuery<string>;
  }): Promise<TAxios> {
    return this.send('get', `/polls/${data.id}/report`, {
      params: { filters: data.filters },
      // params: { filters: JSON.stringify(data.filters) },
    });
  }

  //Подробный отчет по отпросу в excel
  async getPollReportExcel(data: {
    id: number;
    filters?: ParsedQuery<string>;
  }): Promise<TAxios> {
    return this.sendFile('get', `/polls/${data.id}/report/excel`, {
      responseType: 'blob', // Важно для получения бинарных данных
      // headers: {
      //   'Content-Type':
      //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'Content-Disposition': 'attachment; filename="report.xlsx"',
      //   'Accept':
      //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // },
      params: { filters: data.filters },
    });
  }

  //Подробный отчет по отпросу не авторизованный
  async getPollShareReport(data: {
    share: string;
    filters?: ParsedQuery<string>;
  }): Promise<TAxios> {
    return this.send('get', `/polls/share/${data.share}`, {
      params: { filters: data.filters },
    });
  }

  //Получить вопросы по таргетингу
  async getTargetingQuestions<T>(): Promise<T> {
    return this.send('get', `/questions`);
  }

  //POST
  //Создание опроса
  async postPolls(data: IPollsRequestPost): Promise<TAxios> {
    const { images, repeat, ...rest } = data;
    const formdata = new FormData();

    images.forEach((file, i) => {
      formdata.append(`images[${i}]`, file);
    });
    return this.send('post', `/polls`, formdata, {
      params: { ...rest, repeatable: repeat || undefined },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //Создание составного опроса
  async postCompositePolls(data: ICompositePollsRequestPost): Promise<TAxios> {
    const { composite, repeat, ...rest } = data;
    const formdata = new FormData();

    composite.forEach(chapter => {
      chapter.images.forEach((file, y) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formdata.append(`images_${chapter.composite_order}[${y}]`, file);
      });
    });
    return this.send('post', `/polls/composite`, formdata, {
      params: {
        ...rest,
        composite: composite.map(item => {
          const { images, ...itemRest } = item;
          return itemRest;
        }),
        repeatable: repeat || undefined,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //Жалоба
  async postCompliants(data: {
    result_id: number;
    reason: Reason;
    comment?: string;
  }): Promise<TAxios> {
    return this.send('post', `/complaints`, {
      ...data,
    });
  }

  //Подробный отчет по отпросу не авторизованный
  async addPollInYour(data: { share: string }): Promise<TAxios> {
    return this.send('post', `/polls/share/${data.share}`);
  }

  //PUT
  //Обновление опроса
  async putPolls(data: IPollsRequestPut): Promise<TAxios> {
    const { poll, images, ...rest } = data;
    const formdata = new FormData();
    images.forEach((file, i) => {
      formdata.append(`images[${i}]`, file);
    });
    return this.send('post', `/polls/${poll}`, formdata, {
      params: { ...rest, _method: 'PUT' },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //Обновление составного опроса
  async patchCompositePolls(
    data: ICompositePollsRequestPost & { poll: number },
  ): Promise<TAxios> {
    const { poll, composite, ...rest } = data;
    const formdata = new FormData();

    composite.forEach(chapter => {
      chapter.images.forEach((file, y) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formdata.append(`images_${chapter.composite_order}[${y}]`, file);
      });
    });
    return this.send('post', `/polls/composite/${poll}`, formdata, {
      params: {
        ...rest,
        composite: composite.map(item => {
          const { images, ...itemRest } = item;
          return itemRest;
        }),
        _method: 'PUT',
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  // const formdata = new FormData();

  // composite.forEach(chapter => {
  //   chapter.images.forEach((file, y) => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     formdata.append(`images_${chapter.composite_order}[${y}]`, file);
  //   });
  // });
  // return this.send('post', `/polls/composite`, formdata, {
  //   params: {
  //     ...rest,
  //     composite: composite.map(item => {
  //       const { images, ...itemRest } = item;
  //       return itemRest;
  //     }),
  //     repeatable: repeat || undefined,
  //   },
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  // });

  //Лайк
  async putLike(data: {
    poll: number;
    result_id: number;
    likes: number;
    id: number;
  }): Promise<TAxios> {
    const { poll, result_id } = data;
    return this.send('put', `/polls/${poll}/like`, {
      result_id,
    });
  }

  //DELETE
  //Удалить опрос
  async deletePolls(
    data: TPollsRequestGet & { poll: number },
  ): Promise<TAxios> {
    const { poll, ...rest } = data;
    return this.send('delete', `/polls/${poll}`, { params: rest });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const pollsModel = new PollsModel(baseUrl);

export default pollsModel;
