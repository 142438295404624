import React, { lazy, FC, Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import Spiner from 'shared/components/loader/spinner';
import CabinetLayoutRoute from 'shared/layouts/cabinet';
import LandingLayoutRoute from 'shared/layouts/landing';
import BitrixLayoutRoute from 'shared/layouts/bitrix24';
import OzonLayoutRoute from 'shared/layouts/ozon';
import { getIsAuth, getOzonAuth, getUserResponse } from 'store/selectors/user';
import ROUTES from '../shared/constants/routes';
import useHeadTitle from 'shared/helpers/hooks/useHeadTitle';
import { SVGSource } from 'shared/components/icon';
import ScrollToTop from 'shared/components/scroll-to-top/scroll-to-top';
import { REDIRECT_INFO } from 'shared/constants/commmon';
import ErrorPage from './error-page';
import { AgreementPage } from './agreement';
import { SurveyAgreementPage } from './surveyAgreement';
import { PrivacyPage } from './privacy';
import { ReferralAgreementPage } from './referalAgreement';

// import CONFIG from 'shared/constants/config';
// import AllTest from 'pages/cabinet/allTest';

const AllTest = lazy(() => import('./cabinet/allTest'));
const TestItem = lazy(() => import('./cabinet/allTest/item'));
const NewTestPage = lazy(() => import('./cabinet/newTest'));
const EditTest = lazy(() => import('./cabinet/editTest'));
const Balance = lazy(() => import('./cabinet/balance'));
const IndexPage = lazy(() => import('./landing'));
const AboutPage = lazy(() => import('./about'));

const SharePage = lazy(() => import('./share'));
const PromocodePage = lazy(() => import('./promocode'));
const PartnerPage = lazy(() => import('./cabinet/profile/partner'));

const Profile = lazy(() => import('./cabinet/profile'));
const ProfileReferral = lazy(() => import('./cabinet/profile/profileReferral'));
const ProfileInformation = lazy(
  () => import('./cabinet/profile/profileInformation'),
);

const Bitrix24Page = lazy(() => import('./bitrix24'));

const OzonPage = lazy(() => import('./ozon'));
const OzonNoauthPage = lazy(() => import('./ozon/ozonNoauthPage'));

const RespondentPage = lazy(
  () => import('./cabinet/supportOnly/respondent/respondent'),
);

const RespondentsPaymentsPage = lazy(
  () => import('./cabinet/supportOnly/payments/respondentsPayments'),
);

const TroublePollsPage = lazy(
  () => import('./cabinet/supportOnly/trablePolls/trablePolls'),
);

// const ProfileRespondents = lazy(
//   () => import('./cabinet/profile/profileRespondents'),
// );
// const ProfileTariff = lazy(() => import('./cabinet/profile/profileTariff'));
// const FeaturesPage = lazy(() => import('./features'));
// const PricesPage = lazy(() => import('./prices'));
// const SubscriptionsPage = lazy(() => import('./subscriptions'));
// const ReviewsPage = lazy(() => import('./reviews'));
// const TestsPage = lazy(() => import('./tests'));
// const ArticlesPage = lazy(() => import('./articles'));
// const Articles1Page = lazy(() => import('./articles1'));

const Routing: FC = () => {
  const isAuth = useSelector(getIsAuth, shallowEqual);
  const { isOzonAuth } = useSelector(getOzonAuth);
  const headTitle = useHeadTitle();
  const redirectInfo = localStorage.getItem(REDIRECT_INFO);
  if (redirectInfo) {
    const info = JSON.parse(redirectInfo);
    if (info.page === 'edit' && !!info.draft) {
      localStorage.removeItem(REDIRECT_INFO);
      window.location.pathname = `${ROUTES.CABINET_EDIT.ROUTE}/${info.draft}`;
    }
    if (info.page === 'balance') {
      localStorage.removeItem(REDIRECT_INFO);
      window.location.pathname = `${ROUTES.CABINET_BALANCE.ROUTE}`;
    }
  }
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<BitrixLayoutRoute isAuth={isAuth} />}>
          <Route
            path={ROUTES.EMBEDED_BITRIX_LOGIN.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.EMBEDED_BITRIX_NEW.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.EMBEDED_BITRIX_ALL.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          />
        </Route>
        <Route element={<OzonLayoutRoute isAuth={isAuth && isOzonAuth} />}>
          <Route
            path={ROUTES.EMBEDED_OZON.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <OzonPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<OzonLayoutRoute isAuth={isAuth} />}>
          <Route
            path={ROUTES.NO_AUTH_OZON.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <OzonNoauthPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<LandingLayoutRoute isAuth={isAuth} />}>
          {/* <Route
            path={ROUTES.EMBEDED_BITRIX_LOGIN.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.EMBEDED_BITRIX_NEW.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.EMBEDED_BITRIX_ALL.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Bitrix24Page />
              </Suspense>
            }
          /> */}
          <Route
            path={ROUTES.MAIN.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <IndexPage />
              </Suspense>
            }
          />
          {!isAuth && (
            <Route
              path={ROUTES.SURVEY_AGREEMENT.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <SurveyAgreementPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.REFERRAL.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <ReferralAgreementPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.OFFER.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <AgreementPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.PERSONAL.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <PrivacyPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.SHARE.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <SharePage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.PROMO.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <PromocodePage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.ABOUT.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <AboutPage />
                </Suspense>
              }
            />
          )}
          {/* {!isAuth && (
            <Route
              path={ROUTES.FEATURES.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <FeaturesPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.PRICES.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <PricesPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.SUBSCRIPTIONS.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <SubscriptionsPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.REVIEWS.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <ReviewsPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.TESTS.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <TestsPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.ARTICLES.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <ArticlesPage />
                </Suspense>
              }
            />
          )}
          {!isAuth && (
            <Route
              path={ROUTES.ARTICLES1.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <Articles1Page />
                </Suspense>
              }
            />
          )} */}
        </Route>

        <Route element={<CabinetLayoutRoute isAuth={isAuth} />}>
          <Route
            path={ROUTES.CABINET_MAIN.ROUTE}
            element={<>Cabinet index</>}
          />
          {isAuth && (
            <Route
              path={ROUTES.SURVEY_AGREEMENT.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <SurveyAgreementPage />
                </Suspense>
              }
            />
          )}
          {isAuth && (
            <Route
              path={ROUTES.REFERRAL.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <ReferralAgreementPage />
                </Suspense>
              }
            />
          )}
          {isAuth && (
            <Route
              path={ROUTES.OFFER.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <AgreementPage />
                </Suspense>
              }
            />
          )}
          {isAuth && (
            <Route
              path={ROUTES.PERSONAL.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <PrivacyPage />
                </Suspense>
              }
            />
          )}
          {isAuth && (
            <Route
              path={ROUTES.SHARE.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <SharePage />
                </Suspense>
              }
            />
          )}
          {isAuth && (
            <Route
              path={ROUTES.PROMO.ROUTE}
              element={
                <Suspense fallback={<Spiner position="Top" />}>
                  <PromocodePage />
                </Suspense>
              }
            />
          )}

          <Route
            path={ROUTES.CABINET_TESTS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <AllTest />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.TEST_ITEM.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <TestItem />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_NEW_TEST.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <NewTestPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_EDIT_TEST.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <EditTest />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_PROFILE.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_INFORMATION.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ProfileInformation />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_REFERRAL.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ProfileReferral />
              </Suspense>
            }
          />

          <Route
            path={ROUTES.PARTNER_REGISTER.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <PartnerPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_BALANCE.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Balance />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.RESPONDENT_PROFILE.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <RespondentPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.PAYMENTS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <RespondentsPaymentsPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.TROUBLE_POLLS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <TroublePollsPage />
              </Suspense>
            }
          />
          {/* <Route
            path={ROUTES.CABINET_RESPONDENTS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ProfileRespondents />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.CABINET_TARIFF.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ProfileTariff />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.ABOUT.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <AboutPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.FEATURES.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <FeaturesPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.PRICES.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <PricesPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.SUBSCRIPTIONS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <SubscriptionsPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.REVIEWS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ReviewsPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.TESTS.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <TestsPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.ARTICLES.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.ARTICLES1.ROUTE}
            element={
              <Suspense fallback={<Spiner position="Top" />}>
                <Articles1Page />
              </Suspense>
            }
          /> */}

          <Route path={ROUTES.CABINET_HELP.ROUTE} element={<>Help</>} />
        </Route>

        <Route
          path="*"
          element={
            isAuth ? (
              <CabinetLayoutRoute isAuth={isAuth}>
                <ErrorPage
                  errorCode="404"
                  errorText="страница не найдена"
                  errorDescription={
                    <p>
                      Неправильно набран адрес, или такая страница на сайте
                      больше не существует. Вы можете начать с главной страницы.
                    </p>
                  }
                />
              </CabinetLayoutRoute>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </>,
    ),
  );

  return (
    <>
      <ScrollToTop />
      <Helmet title={headTitle} />
      <RouterProvider router={router} />
      <SVGSource />
    </>
  );
};

export default Routing;
