import { memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import ROUTES from 'shared/constants/routes';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';
import { getOzonAuth } from 'store/selectors/user';

type TLandingLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const OzonLayoutRoute = ({ isAuth, children }: TLandingLayoutRoute) => {
  const { isOzon, originDomIsOzon } = useIsIframe();
  const { isOzonAuth, isLocalStorageOzonAuth } = useSelector(getOzonAuth);

  if (
    isAuth &&
    (isOzonAuth || isLocalStorageOzonAuth) &&
    (isOzon || originDomIsOzon)
  ) {
    return <Navigate to={ROUTES.CABINET_TESTS.ROUTE} replace />;
  }

  return <>{children ?? <Outlet />}</>;
};

export default memo(OzonLayoutRoute);
