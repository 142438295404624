export default {
  shuffleArray(array: any[]): any[] {
    const a = [...array];
    // eslint-disable-next-line no-plusplus
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  },
  addSpaces: (nStr: string | number): string => {
    const x = String(nStr).split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1 $2`);
    }
    return x1 + x2;
  },
  bodyFixPosition: (): void => {
    setTimeout(() => {
      /* Ставим необходимую задержку, чтобы не было «конфликта» в случае, если функция фиксации вызывается сразу после расфиксации (расфиксация отменяет действия расфиксации из-за одновременного действия) */

      if (!document.body.hasAttribute('data-body-scroll-fix')) {
        // Получаем позицию прокрутки
        const scrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;

        // Ставим нужные стили
        document.body.setAttribute(
          'data-body-scroll-fix',
          String(scrollPosition),
        ); // Cтавим атрибут со значением прокрутки
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.left = '0';
        document.body.style.width = '100%';
      }
    }, 15); /* Можно задержку ещё меньше, но у меня работало хорошо именно с этим значением на всех устройствах и браузерах */
  },
  bodyUnfixPosition: (): void => {
    if (document.body.hasAttribute('data-body-scroll-fix')) {
      // Получаем позицию прокрутки из атрибута
      const scrollPosition = document.body.getAttribute('data-body-scroll-fix');

      // Удаляем атрибут
      document.body.removeAttribute('data-body-scroll-fix');

      // Удаляем ненужные стили
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.width = '';

      // Прокручиваем страницу на полученное из атрибута значение
      window.scroll(0, Number(scrollPosition));
    }
  },
  isMobile: (): boolean =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ),
  // TODO - navigator.userAgentData.platform
  isWindows: (): boolean => window.navigator.platform === 'Win32',

  isProd: (): boolean => {
    return window.origin.includes('seller.de.ru');
  },
  isDev: (): boolean => {
    return window.origin.includes('dev.frontend.de.ru');
  },

  getFloorNumberKeyWords: (arg: number): number => {
    return arg < 1000 ? Math.floor(arg / 100) / 10 : Math.floor(arg / 1000);
  },

  convertZeroToUndefined: (maxcount: number | null): number | undefined => {
    return maxcount !== null && maxcount !== 0 ? maxcount : undefined;
  },

  setFocus: (id: string, targetId: string): void => {
    if (!document.activeElement?.id.includes(id)) {
      const nextSibling = document.getElementById(targetId) as HTMLInputElement;
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  },
  scroolTo: (id: string) => {
    const element = document.querySelector(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },

  getTimeAgo: dateString => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((+now - +date) / 1000); // Разница в секундах

    // Функция для склонения слов в зависимости от числа
    function declension(num, words) {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        num % 100 > 4 && num % 100 < 20 ? 2 : cases[Math.min(num % 10, 5)]
      ];
    }

    if (diffInSeconds < 60) {
      return 'только что';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);

      return `${declension(minutes, [
        'Прошла',
        'Прошло',
        'Прошло',
      ])} ${minutes} ${declension(minutes, ['минута', 'минуты', 'минут'])}`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${declension(hours, [
        'Прошел',
        'Прошло',
        'Прошло',
      ])} ${hours} ${declension(hours, ['час', 'часа', 'часов'])}`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${declension(days, [
        'Прошел',
        'Прошло',
        'Прошло',
      ])} ${days} ${declension(days, ['день', 'дня', 'дней'])}`;
    }
  },
};
