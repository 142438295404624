import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FieldSet } from 'shared/components/grid/field';
import Select from 'react-select/async';
import { singleSelectStyles } from 'app/styles/select';
import Button from 'shared/components/button';
import { useSelector } from 'react-redux';
import { getAccess } from 'features/AdminSupport/model/services/getAccess/getAccess';
import { getAccessData } from 'features/AdminSupport/model/selectors/accessSupport/getAccessData/getAccessData';
import { getAccessIsLoading } from 'features/AdminSupport/model/selectors/accessSupport/getAccessIsLoading/getAccessIsLoading';
import { AUTH_TOKEN, ORIGIN_AUTH_TOKEN } from 'shared/constants/commmon';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { ModalFooter, ModalTitle } from 'shared/components/modal/modal';
import supportModel from 'store/models/support';
import { mapDataClientsList } from 'features/AdminSupport/model/helpers/mapDataClientsList/mapDataClientsList';
import axios, { CancelTokenSource } from 'axios';
import Input from 'shared/components/input';
import { fetchBonusDeposit } from 'features/AdminSupport/model/services/respondents/transactions';
import { TextArea } from 'shared/components/textarea';

import styles from './bonusDepositForm.module.scss';
import {
  getBonusDepositIsPayment,
  getBonusDepositLoading,
  getBonusDepositError,
} from 'features/AdminSupport/model/selectors/payments/paymentsTransactions';

export type AdminSupportFormProps = {
  onSuccess: () => void;
};

interface OptionType {
  value: number;
  label: string;
}

export const BonusDepositForm = memo((props: AdminSupportFormProps) => {
  const { onSuccess } = props;
  const dispatch = useAppDispatch();
  const userAccess = useSelector(getAccessData);
  const userAccessIsLoadind = useSelector(getAccessIsLoading);
  const bonusDepositIsPayment = useSelector(getBonusDepositIsPayment);
  const bonusDepositLoading = useSelector(getBonusDepositLoading);
  const bonusDepositError = useSelector(getBonusDepositError);

  const [errorSearch, setErrorSearch] = useState('');
  const [user, setUser] = useState<{ value: string; label: string } | null>(
    null,
  );
  const [bonusAmount, setBonusAmount] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [bonusError, setBonusError] = useState<string>('');

  const handleBonusAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      // Проверяем, что введено только число
      if (!/^\d*$/.test(value)) {
        return;
      }

      const numValue = parseInt(value, 10);

      // Проверяем диапазон
      if (numValue > 10000) {
        setBonusError('Сумма не может превышать 10000');
        return;
      }

      setBonusError('');
      setBonusAmount(value);
    },
    [],
  );

  const changeComment = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(e.target.value);
    },
    [],
  );

  const onUserChange = useCallback(value => {
    setUser(value);
  }, []);

  const getAccessSupport = useCallback(() => {
    if (user?.value) {
      dispatch(getAccess(+user.value));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (userAccess.user?.token) {
      const originToken = localStorage.getItem(AUTH_TOKEN);
      if (originToken) {
        localStorage.setItem(ORIGIN_AUTH_TOKEN, originToken);
        localStorage.setItem(AUTH_TOKEN, JSON.stringify(userAccess.user.token));
        onSuccess();
        document.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccess.user]);

  const debounce = (func: (...args: unknown[]) => void, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: unknown[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const abortControllerRef = useRef<CancelTokenSource | null>(null);

  const loadOptions = async (
    inputValue: string,
    callback: (options: OptionType[]) => void,
  ) => {
    // Здесь выполняется асинхронный запрос к API
    setErrorSearch('');
    if (abortControllerRef.current) {
      abortControllerRef.current.cancel('Отмена предыдущего запроса');
    }

    // Создаем новый источник отмены
    const cancelTokenSource = axios.CancelToken.source();

    abortControllerRef.current = cancelTokenSource;
    try {
      const response = await supportModel.getClinetsList({
        query: inputValue || undefined,
        cancelToken: cancelTokenSource.token,
      });
      const optionss: OptionType[] = mapDataClientsList(response.data);
      callback(optionss);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setErrorSearch('Ошибка при загрузке данных');
      }
    }
  };

  // Создаем ref для хранения функции debounce
  const debouncedLoadOptionsRef = useRef(
    debounce(
      (inputValue: string, callback: (options: OptionType[]) => void) => {
        loadOptions(inputValue, callback);
      },
      1500,
    ), // Задержка 1500 мс
  );

  // Используем useCallback с явным указанием зависимостей
  const debouncedLoadOptions = useCallback(
    (inputValue: string, callback: (options: OptionType[]) => void) => {
      debouncedLoadOptionsRef.current(inputValue, callback);
    },
    [], // Зависимости не требуются, так как debouncedLoadOptionsRef стабилен
  );

  // fetchBonusDeposit
  const setBonusDeposit = useCallback(async () => {
    if (user?.value && bonusAmount && comment) {
      const request = {
        id: +user.value,
        sum: +bonusAmount,
        comment: comment,
      };
      dispatch(fetchBonusDeposit(request));
    }
  }, [user, bonusAmount, comment, dispatch]);

  return (
    <>
      <ModalTitle>Зачисление бонуса пользователю</ModalTitle>
      {!bonusDepositIsPayment && (
        <>
          <FieldSet labelName="Список доступных пользователей">
            <Select<OptionType>
              inputId="respondents"
              styles={singleSelectStyles()}
              cacheOptions
              defaultOptions
              loadOptions={debouncedLoadOptions}
              onChange={onUserChange}
              placeholder="Выбрать"
              noOptionsMessage={() =>
                errorSearch ? errorSearch : 'Значение не найдено'
              }
              loadingMessage={() => 'Загрузка...'}
            />
          </FieldSet>
          <FieldSet labelName="Сумма зачисления" subError={bonusError}>
            <Input
              type="text"
              value={bonusAmount}
              onChange={handleBonusAmountChange}
              placeholder="Введите сумму от 0 до 10000"
            />
          </FieldSet>
          <FieldSet labelName="Комментарий">
            <TextArea
              value={comment}
              maxLength={255}
              onChange={changeComment}
            />
          </FieldSet>
        </>
      )}
      {bonusDepositIsPayment && (
        <span>Бонус зачислен, подключиться к кабинету пользователя?</span>
      )}
      {!bonusDepositIsPayment && (
        <ModalFooter>
          <Button
            type="button"
            onClick={setBonusDeposit}
            disabled={!user || bonusDepositLoading || !bonusAmount || !comment}
            as="button"
            withIcon={false}
          >
            Зачислить бонус
          </Button>
          {!!bonusDepositError && (
            <span className={styles.error}>{bonusDepositError.message}</span>
          )}
        </ModalFooter>
      )}
      {!!bonusDepositIsPayment && (
        <ModalFooter>
          <Button
            type="button"
            onClick={getAccessSupport}
            as="button"
            withIcon={false}
            disabled={userAccessIsLoadind.isLoading}
          >
            Получить доступ
          </Button>
        </ModalFooter>
      )}
    </>
  );
});
