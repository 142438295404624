import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';

export const getRespondentProfile = createAsyncThunk(
  'support/getRespondentProfile',
  async (id: string, thunkApi) => {
    try {
      const response = await supportModel.getRespondentProfile({ id });

      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
