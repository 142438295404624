import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useRedirectOverPagination = (
  lastPage: string | undefined,
  page: string | undefined,
  to: string,
  toPage = 1,
  pageName?: string,
) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (lastPage && page && +page > +lastPage) {
      const queryParam = queryString.parse(search);
      const setSearch: (arg: number) => string = val =>
        queryString.stringify({ ...queryParam, [pageName || 'page']: val });
      navigate(`${to}?${setSearch(toPage)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPage, navigate, page]);
};
