import { SectionsId } from 'pages/landing';
import { memo, ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { AuthBlock, ButtonsComponent } from 'entities/AuthBlock/';
import BurgerBlock from 'shared/components/burgerBlock';
import Footer from 'shared/components/footer';
import Header from 'shared/components/header';
import Logo from 'shared/components/logo';
import MainWrapper from 'shared/components/mainWrapper';
import Navbar from 'shared/components/navbar';
import ROUTES from 'shared/constants/routes';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';

type TLandingLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const LandingLayoutRoute = ({ isAuth, children }: TLandingLayoutRoute) => {
  const { pathname } = useLocation();
  const isSharePage = pathname.includes('/share/');
  const isAboutPage = pathname.includes('/about');
  const isIndexPage = !isSharePage && !isAboutPage;
  const { originDomIsOzon, topService } = useIsIframe();

  if (isAuth) {
    return <Navigate to={ROUTES.CABINET_TESTS.ROUTE} replace />;
  }

  if (!isAuth && originDomIsOzon && topService === 'isFrame') {
    return <Navigate to={ROUTES.NO_AUTH_OZON.ROUTE} replace />;
  }

  const headerLinks = [
    {
      ROUTE: isIndexPage ? SectionsId.How : `/#${SectionsId.How}`,
      NAME: 'Как это работает',
      ANKER: isIndexPage,
    },
    {
      ROUTE: isIndexPage ? SectionsId.Reviews : `/#${SectionsId.Reviews}`,
      NAME: 'Отзывы',
      ANKER: isIndexPage,
    },
    {
      ROUTE: isIndexPage ? SectionsId.Tariffs : `/#${SectionsId.Tariffs}`,
      NAME: 'Тарифы',
      ANKER: isIndexPage,
    },
  ];

  return (
    <>
      <Header layout="Index">
        <Logo to={ROUTES.MAIN.ROUTE} layout="Index" />
        <BurgerBlock>
          <Navbar links={headerLinks} layout="Index" position="Mobile" />
          <ButtonsComponent position="Mobile" />
        </BurgerBlock>
        <Navbar links={headerLinks} layout="Index" position="Desktop" />
        <AuthBlock type="header" />
      </Header>
      <MainWrapper>{children ?? <Outlet />}</MainWrapper>
      <Footer layout="Index" />
    </>
  );
};

export default memo(LandingLayoutRoute);
