import { TState } from 'store/types';

//Неодобренные платежи
export const getNotApprovedPaymentsData = (state: TState) =>
  state.adminSupport.payments.notApproved.data;

export const getNotApprovedPaymentsMetsData = (state: TState) =>
  state.adminSupport.payments.notApproved.metaData;

export const getNotApprovedPaymentsLoading = (state: TState) =>
  state.adminSupport.payments.notApproved.loading;

export const getNotApprovedPaymentsError = (state: TState) =>
  state.adminSupport.payments.notApproved.error;

//Непрошедшие платежи
export const getNotPaidPaymentsData = (state: TState) =>
  state.adminSupport.payments.notPaid.data;

export const getNotPaidPaymentsMetsData = (state: TState) =>
  state.adminSupport.payments.notPaid.metaData;

export const getNotPaidPaymentsLoading = (state: TState) =>
  state.adminSupport.payments.notPaid.loading;

export const getNotPaidPaymentsError = (state: TState) =>
  state.adminSupport.payments.notPaid.error;

export const getNotifyToModeratorError = (state: TState) =>
  state.adminSupport.payments.notApproved.notifyError;

export const getRetryPaymentError = (state: TState) =>
  state.adminSupport.payments.notPaid.notifyError;

//Бонусный депозит
export const getBonusDepositIsPayment = (state: TState) =>
  state.adminSupport.bonusDeposit.isPayment;

export const getBonusDepositLoading = (state: TState) =>
  state.adminSupport.bonusDeposit.loading;

export const getBonusDepositError = (state: TState) =>
  state.adminSupport.bonusDeposit.error;
