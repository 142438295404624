import { FC, PropsWithChildren } from 'react';
import { Icon, TIcon } from 'shared/components/icon';
import Button from 'shared/components/button';
import Label from '../label';
import styles from './index.module.scss';
import cl from 'classnames';

type TFieldSet = {
  labelName?: string;
  labelFor?: string;
  subText?: string;
  subError?: string;
  className?: string;
  bordered?: boolean;
  subTexts?: string[];
};

const FieldSet: FC<PropsWithChildren & TFieldSet> = ({
  children,
  labelName,
  labelFor,
  bordered,
  subText,
  subTexts,
  subError,
  className,
}) => {
  return (
    <fieldset
      className={cl(className, styles.fieldset, {
        [styles.bordered]: bordered,
      })}
    >
      {labelName && <Label htmlFor={labelFor}>{labelName}</Label>}
      {subText && <p className={styles.subText}>{subText}</p>}
      {subTexts &&
        subTexts.map((text, i) => (
          <p className={styles.subTexts} key={i}>
            {text}
          </p>
        ))}
      {subError && <p className={styles.subError}>{subError}</p>}
      {children}
    </fieldset>
  );
};

const enum RowType {
  Row = 'Row',
  Column = 'Column',
}

type TFieldSetRow = {
  labelName?: string;
  labelFor?: string;
  direction?: keyof typeof RowType;
  duo?: boolean;
  className?: string;
};

const FieldRow: FC<PropsWithChildren & TFieldSetRow> = ({
  children,
  direction = RowType.Row,
  duo = false,
  className,
}) => {
  return (
    <div
      className={cl(styles.row, className, {
        [styles.rowColumn]: direction === RowType.Column,
        [styles.duo]: duo === true,
      })}
    >
      {children}
    </div>
  );
};

type TFieldList = {
  className?: string;
};

const FieldList: FC<PropsWithChildren & TFieldList> = ({
  children,
  className,
}) => {
  return <div className={cl(styles.list, className)}>{children}</div>;
};

type TFieldListAction = {
  className?: string;
  iconName?: TIcon;
  labelName?: string;
  labelFor?: string;
  onClick?: () => void;
};

const FieldListAction: FC<PropsWithChildren & TFieldListAction> = ({
  children,
  className,
  onClick,
  iconName = 'icon-plus',
  labelName,
  labelFor,
}) => {
  return (
    <div className={cl(styles.list_action, className)}>
      {labelName && <Label htmlFor={labelFor}>{labelName}</Label>}
      <div className={styles.list_content}>{children}</div>
      <Button
        className={styles.list_button}
        as="button"
        transparent
        nopadding
        onClick={onClick}
      >
        <Icon name={iconName}></Icon>
      </Button>
    </div>
  );
};

export { FieldRow, FieldSet, FieldList, FieldListAction };
