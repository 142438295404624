import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import styles from './index.module.scss';
import cl from 'classnames';
import CONFIG from 'shared/constants/config';
import { Layouts, LayoutValues } from 'shared/layouts/types';
import { useSelector } from 'react-redux';
import { getOzonAuth } from 'store/selectors/user';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';

type TLogo = {
  to?: string;
  layout?: LayoutValues;
};

const Logo = ({ to, layout }: TLogo) => {
  const isIndex = layout === Layouts.Index;
  const isCabinet = layout === Layouts.Cabinet;
  const { isOzonAuth, isLocalStorageOzonAuth } = useSelector(getOzonAuth);
  const { originDomIsOzon } = useIsIframe();

  return (
    <div
      className={cl(styles.logo, {
        [styles.cabinet]: isCabinet,
        [styles.index]: isIndex,
      })}
    >
      {!!to && !isOzonAuth && !isLocalStorageOzonAuth && !originDomIsOzon ? (
        <Link className={styles.link} to={to || ROUTES.MAIN.ROUTE}>
          <img
            className={cl(styles.img, {
              [styles.cabinet]: isCabinet,
              [styles.index]: isIndex,
            })}
            src="/logo/logo.svg"
            alt={CONFIG.PROJECT_NAME}
          />
        </Link>
      ) : (
        <div className={styles.link}>
          <img
            className={cl(styles.img, {
              [styles.cabinet]: isCabinet,
              [styles.index]: isIndex,
            })}
            src="/logo/logo.svg"
            alt={CONFIG.PROJECT_NAME}
          />
        </div>
      )}

      <span
        className={cl(styles.copy, {
          [styles.cabinet]: isCabinet,
          [styles.index]: isIndex,
        })}
      >
        Designed{isCabinet ? <br /> : <></>} for decisions
      </span>
    </div>
  );
};

export default Logo;
