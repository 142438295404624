import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParsedQuery } from 'query-string';
import NetworkError from 'shared/exceptions/network';
import pollsModel, {
  ICompositePollsRequestPost,
  IPollsRequestPost,
  IPollsRequestPut,
  TPollsRequestGet,
} from 'store/models/pools';
import initialState from './initial';
import {
  TFilters,
  TMeta,
  TPoll,
  TPollReport,
  TPollResponse,
  TPollStarImages,
  TPollStarReport,
  TResultsStars,
  TTargetingQuestionType,
} from './types';
import { setClientBalance } from 'pages/user/store/store';
import { TEST_TYPE } from 'shared/constants/types';
import fileDownload from 'js-file-download';

//GET
export const fetchAllPolls = createAsyncThunk(
  'polls/fetchAllPolls',
  async (data: TPollsRequestGet, { rejectWithValue }) => {
    try {
      const response = (await pollsModel.getPolls(
        data,
      )) as unknown as TPollResponse & TMeta;
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchFilters = createAsyncThunk(
  'polls/fetchFilters',
  async (
    data: {
      filters?: ParsedQuery<string> | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getFilters(data)) as unknown as {
        data: TFilters;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPoll = createAsyncThunk(
  'polls/fetchPoll',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = (await pollsModel.getPoll(id)) as unknown as {
        data: TPoll;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPollReport = createAsyncThunk(
  'polls/fetchPollReport',
  async (
    data: { id: string; filters?: ParsedQuery<string>; isFilters?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getPollReport(data)) as unknown as {
        data: TPollReport | TPollStarReport;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPollReportExcel = createAsyncThunk(
  'polls/fetchPollReportExcel',
  async (data: { id: number; filters?: ParsedQuery<string> }, thunkApi) => {
    try {
      const response = await pollsModel.getPollReportExcel(data);

      if (!response) {
        throw new Error();
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const contentDisposition = response?.headers?.get('Content-Disposition');
      let filename = `poll_report_${new Date().toJSON().slice(0, 10)}.xlsx`;

      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches?.[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      fileDownload(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        response.data,
        filename,
      );
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const fetchPollShareReport = createAsyncThunk(
  'polls/fetchPollShareReport',
  async (
    data: { share: string; filters?: ParsedQuery<string>; isFilters?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getPollShareReport(
        data,
      )) as unknown as {
        data: TPollReport | TPollStarReport;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchTargetingQuestions = createAsyncThunk(
  'polls/fetchTargetingQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: { data: TTargetingQuestionType[] } =
        await pollsModel.getTargetingQuestions();
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//POST
export const fetchNewPoll = createAsyncThunk(
  'polls/fetchNewPoll',
  async (data: IPollsRequestPost, { rejectWithValue, dispatch }) => {
    try {
      const response = (await pollsModel.postPolls(data)) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchNewCompositePoll = createAsyncThunk(
  'polls/fetchNewCompositePoll',
  async (data: ICompositePollsRequestPost, { rejectWithValue, dispatch }) => {
    try {
      const response = (await pollsModel.postCompositePolls(
        data,
      )) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//POST
export const addPollInYourList = createAsyncThunk(
  'polls/addPollInYourList',
  async (data: { share: string }, { rejectWithValue }) => {
    try {
      const response = await pollsModel.addPollInYour(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//PUT
export const fetchEditPoll = createAsyncThunk(
  'polls/fetchEditPoll',
  async (data: IPollsRequestPut, { rejectWithValue, dispatch }) => {
    try {
      const response = (await pollsModel.putPolls(data)) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchEditCompositePoll = createAsyncThunk(
  'polls/fetchEditCompositePoll',
  async (
    data: ICompositePollsRequestPost & { poll: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = (await pollsModel.patchCompositePolls(
        data,
      )) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchLike = createAsyncThunk(
  'polls/fetchLike',
  async (
    data: {
      poll: number;
      result_id: number;
      likes: number;
      id: number;
      increase: boolean;
    },
    thunkApi,
  ) => {
    try {
      const response = (await pollsModel.putLike(data)) as unknown as {
        message: string;
      };
      if (response.message !== 'success') {
        thunkApi.dispatch(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          authSlice.actions.setLike({
            id: data.id,
            result_id: data.result_id,
            increase: !data.increase,
            poll: data.poll,
          }),
        );
        throw new Error();
      }
      return response;
    } catch (e) {
      thunkApi.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        authSlice.actions.setLike({
          id: data.id,
          result_id: data.result_id,
          increase: !data.increase,
          poll: data.poll,
        }),
      );
      return thunkApi.rejectWithValue(e);
    }
  },
);

//DELETE
export const fetchDeletePolls = createAsyncThunk(
  'polls/fetchDeletePolls',
  async (
    data: TPollsRequestGet & { poll: number } & { edit?: true },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.deletePolls(
        data,
      )) as unknown as TPollResponse & TMeta;
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const authSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    clearNewPoll(state) {
      state.newPoll = initialState.newPoll;
    },
    clearPolls(state) {
      state.polls.response = null;
      state.polls.loading = false;
      state.polls.error = null;
    },
    setLike(
      state,
      action: PayloadAction<{
        id: number;
        result_id: number;
        increase: boolean;
        poll: number;
      }>,
    ) {
      if (
        state.pollReport.response?.data &&
        'id' in state.pollReport.response.data &&
        !('composite' in state.pollReport.response.data)
      ) {
        state.pollReport.response.data.images =
          state.pollReport.response.data.images.map(images => {
            if (images.id === action.payload.id) {
              return {
                ...images,
                results: images.results.map(results => {
                  if (results.id === action.payload.result_id) {
                    if (action.payload.increase) {
                      return { ...results, likes: results.likes + 1 };
                    } else return { ...results, likes: results.likes - 1 };
                  } else return results;
                }),
              };
            } else return images;
          });
      }
      if (
        state.pollReport.response?.data &&
        'composite' in state.pollReport.response.data &&
        state.pollReport.response.data.composite
      ) {
        const composite =
          state.pollReport.response.data.composite?.map(item => {
            if (item.id === action.payload.poll) {
              return {
                ...item,
                images: item.images.map(images => {
                  if (images.id === action.payload.id) {
                    return {
                      ...images,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      results: images.results.map(results => {
                        if (results.id === action.payload.result_id) {
                          if (action.payload.increase) {
                            return { ...results, likes: results.likes + 1 };
                          } else
                            return { ...results, likes: results.likes - 1 };
                        } else return results;
                      }),
                    };
                  } else return images;
                }),
              };
            }
            return item;
          }) || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.pollReport.response.data.composite = composite;
      }
    },
    setCompliant(
      state,
      action: PayloadAction<{
        id: number;
        result_id: number;
        poll: number;
      }>,
    ) {
      if (
        state.pollReport.response?.data &&
        'id' in state.pollReport.response.data &&
        !('composite' in state.pollReport.response.data)
      ) {
        state.pollReport.response.data.images =
          state.pollReport.response.data.images.map(images => {
            if (images.id === action.payload.id) {
              return {
                ...images,
                results: images.results.map(results => {
                  if (results.id === action.payload.result_id) {
                    return { ...results, complaint: !results.complaint };
                  } else return results;
                }),
              };
            } else return images;
          });
      }
      if (
        state.pollReport.response?.data &&
        'composite' in state.pollReport.response.data &&
        state.pollReport.response.data.composite
      ) {
        const composite =
          state.pollReport.response.data.composite?.map(item => {
            if (item.id === action.payload.poll) {
              return {
                ...item,
                images: item.images.map(images => {
                  if (images.id === action.payload.id) {
                    return {
                      ...images,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      results: images.results.map(results => {
                        if (results.id === action.payload.result_id) {
                          return { ...results, complaint: !results.complaint };
                        } else return results;
                      }),
                    };
                  } else return images;
                }),
              };
            }
            return item;
          }) || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.pollReport.response.data.composite = composite;
      }
    },
    removeIsDelete(state) {
      state.polls.isDelete = undefined;
    },
    removeErrorStartPoll(state) {
      state.polls.error = null;
      state.newPoll.error = null;
    },
    removeAdded(state) {
      state.addPoll.added = false;
    },
    clearDownloadPollReport(state) {
      state.downloadPollReport.error = undefined;
      state.downloadPollReport.loading = false;
    },
  },

  extraReducers: builder => {
    //GET
    //polls/fetchPoll
    builder.addCase(fetchPoll.pending, state => {
      state.editPoll.loading = true;
      state.editPoll.error = null;
      state.editPoll.response = null;
    });
    builder.addCase(fetchPoll.fulfilled, (state, { payload }) => {
      state.editPoll.loading = false;
      state.editPoll.error = null;
      state.editPoll.response = { data: payload.data };
    });
    builder.addCase(fetchPoll.rejected, (state, action) => {
      state.editPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.editPoll.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchAllPolls
    builder.addCase(fetchAllPolls.pending, state => {
      state.polls.loading = true;
      state.polls.error = null;
    });
    builder.addCase(fetchAllPolls.fulfilled, (state, { payload }) => {
      state.polls.loading = false;
      state.polls.error = null;
      state.polls.response = payload;
    });
    builder.addCase(fetchAllPolls.rejected, (state, action) => {
      state.polls.loading = false;
      const message = (action.payload as NetworkError).message;
      state.polls.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchFilters
    builder.addCase(fetchFilters.pending, state => {
      state.filters.loading = true;
      state.filters.error = null;
    });
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => {
      state.filters.loading = false;
      state.filters.error = null;
      state.filters.response = payload;
    });
    builder.addCase(fetchFilters.rejected, (state, action) => {
      state.filters.loading = false;
      const message = (action.payload as NetworkError).message;
      state.filters.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchPollReport
    builder.addCase(fetchPollReport.pending, (state, { meta }) => {
      if (!meta.arg.isFilters) {
        state.pollReport.loading = true;
      }
      state.pollReport.error = null;
    });
    builder.addCase(fetchPollReport.fulfilled, (state, { payload }) => {
      state.pollReport.loading = false;
      state.pollReport.error = null;

      // if (payload.data.type !== TEST_TYPE.STAR) {
      state.pollReport.response = payload as { data: TPollReport };
      // }
      // if (payload.data.type === TEST_TYPE.STAR) {
      //   state.pollReport.response = {
      //     ...payload,
      //     data: {
      //       ...payload.data,
      //       images: Object.keys((payload.data as TPollStarReport).results).map(
      //         key => ({
      //           ...(payload.data as TPollStarReport).results[key],
      //           star: key,
      //         }),
      //       ),
      //       results: payload.data.images as unknown as TPollStarImages[],
      //     },
      //   };
      // }
    });
    builder.addCase(fetchPollReport.rejected, (state, action) => {
      state.pollReport.loading = false;
      const message = (action.payload as NetworkError).message;
      state.pollReport.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchPollReportExcel
    builder.addCase(fetchPollReportExcel.pending, state => {
      state.downloadPollReport.error = undefined;
      state.downloadPollReport.loading = true;
    });
    builder.addCase(fetchPollReportExcel.fulfilled, state => {
      state.downloadPollReport.error = undefined;
      state.downloadPollReport.loading = false;
    });
    builder.addCase(fetchPollReportExcel.rejected, (state, action) => {
      state.downloadPollReport.loading = false;
      const message = (action.payload as NetworkError).message;
      state.downloadPollReport.error = {
        ...(action.payload as NetworkError),
        message,
      };
    });

    //polls/fetchPollShareReport
    builder.addCase(fetchPollShareReport.pending, (state, { meta }) => {
      if (!meta.arg.isFilters) {
        state.pollReport.loading = true;
      }
      state.pollReport.error = null;
    });
    builder.addCase(fetchPollShareReport.fulfilled, (state, { payload }) => {
      state.pollReport.loading = false;
      state.pollReport.error = null;
      // if (payload.data.type !== TEST_TYPE.STAR) {
      state.pollReport.response = payload as { data: TPollReport };
      // }
      // if (payload.data.type === TEST_TYPE.STAR) {
      //   state.pollReport.response = {
      //     ...payload,
      //     data: {
      //       ...payload.data,
      //       images: Object.keys((payload.data as TPollStarReport).results).map(
      //         key => ({
      //           ...(payload.data as TPollStarReport).results[key],
      //           star: key,
      //         }),
      //       ),
      //       results: payload.data.images as unknown as TPollStarImages[],
      //     },
      //   };
      // }
    });
    builder.addCase(fetchPollShareReport.rejected, (state, action) => {
      state.pollReport.loading = false;
      const message = (action.payload as NetworkError).message;
      state.pollReport.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchTargetingQuestions
    builder.addCase(fetchTargetingQuestions.pending, state => {
      state.targeting.loading = true;
      state.targeting.error = undefined;
    });
    builder.addCase(fetchTargetingQuestions.fulfilled, (state, { payload }) => {
      state.targeting.loading = false;
      state.targeting.error = undefined;
      state.targeting.questions =
        [...payload.data]?.sort((a, b) =>
          a.formulation > b.formulation ? 1 : -1,
        ) || [];
    });
    builder.addCase(fetchTargetingQuestions.rejected, (state, action) => {
      state.targeting.loading = false;
      const message = (action.payload as NetworkError).message;
      state.targeting.error = { ...(action.payload as NetworkError), message };
    });

    //POST
    //polls/fetchNewPoll
    builder.addCase(fetchNewPoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchNewPoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchNewPoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchNewCompositePoll
    builder.addCase(fetchNewCompositePoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchNewCompositePoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchNewCompositePoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //POST
    //polls/addPollInYourList
    builder.addCase(addPollInYourList.pending, state => {
      state.addPoll.loading = true;
      state.addPoll.error = undefined;
    });
    builder.addCase(addPollInYourList.fulfilled, state => {
      state.addPoll.loading = false;
      state.addPoll.error = undefined;
      state.addPoll.added = true;
    });
    builder.addCase(addPollInYourList.rejected, (state, action) => {
      state.addPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.addPoll.error = { ...(action.payload as NetworkError), message };
    });

    //PUT
    //polls/fetchEditPoll
    builder.addCase(fetchEditPoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchEditPoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchEditPoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchEditCompositePoll
    builder.addCase(fetchEditCompositePoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchEditCompositePoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchEditCompositePoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //DELETE
    //polls/fetchDeletePolls
    builder.addCase(fetchDeletePolls.pending, state => {
      state.polls.loading = true;
      state.polls.error = null;
    });
    builder.addCase(fetchDeletePolls.fulfilled, (state, { payload, meta }) => {
      state.polls.loading = false;
      state.polls.error = null;
      if (meta.arg.edit) {
        state.polls.isDelete = true;
      }
      if (!meta.arg.edit) {
        state.polls.response = payload;
      }
    });
    builder.addCase(fetchDeletePolls.rejected, (state, action) => {
      state.polls.loading = false;
      const message = (action.payload as NetworkError).message;
      state.polls.error = { ...(action.payload as NetworkError), message };
    });
  },
});

export default authSlice.reducer;
export const {
  clearNewPoll,
  clearPolls,
  setLike,
  removeIsDelete,
  removeErrorStartPoll,
  setCompliant,
  removeAdded,
  clearDownloadPollReport,
} = authSlice.actions;
