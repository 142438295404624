import { Modal } from 'shared/components/modal/modal';
import { Suspense } from 'react';
import { AdminSupportForm } from './form/AdminSupportForm.async';

interface AdminSupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdminSupportModal = (props: AdminSupportModalProps) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Suspense fallback={''}>
        <AdminSupportForm onSuccess={onClose} />
      </Suspense>
    </Modal>
  );
};

export { AdminSupportModal };
