import { createAsyncThunk } from '@reduxjs/toolkit';
import pollsModel from 'store/models/pools';
import { Reason } from '../types/compliantSchema';
import { setCompliant } from 'pages/cabinet/store/store';

export const fetchCompliants = createAsyncThunk(
  'compliants/fetchCompliants',
  async (
    data: {
      result_id: number;
      reason: Reason;
      comment?: string | undefined;
      image_id?: number;
      poll: number;
    },
    thunkApi,
  ) => {
    try {
      const { poll, ...rest } = data;
      const response = (await pollsModel.postCompliants(rest)) as unknown as {
        message: string;
      };
      if (!response.message) {
        throw new Error();
      }
      if (response.message && data.image_id) {
        thunkApi.dispatch(
          setCompliant({
            id: data.image_id,
            result_id: data.result_id,
            poll,
          }),
        );
      }
      return response.message;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
