import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import { clientsList } from '../services/clientsList/clientsList';
import { getAccess } from '../services/getAccess/getAccess';
import { AdminSupportSchema } from '../types/adminSupportSchema';
import { getRespondentProfile } from '../services/respondents/respondents';
import {
  fetchBonusDeposit,
  fetchNotifyToModerator,
  fetchTransactionsRetry,
  getNotApprovedTransactions,
  getNotPaidTransactions,
  getRespondentTransactions,
} from '../services/respondents/transactions';
import { getRespondentResults } from '../services/respondents/results';
import { getRespondentComplaints } from '../services/respondents/complaints';
import {
  getTroublePollsNotApproved,
  getTroublePollsProlonged,
} from '../services/respondents/troublePolls';

const initialState: AdminSupportSchema = {
  clients: [],
  isLoading: false,
  error: null,
  access: {
    user: undefined,
    isLoading: false,
    error: null,
  },
  respondent: {
    profile: {
      data: undefined,
      loading: false,
    },
    transactions: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
    results: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
    complaints: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
  },
  payments: {
    notApproved: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
    notPaid: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
  },
  troublePolls: {
    notApproved: {
      data: undefined,
      metaData: undefined,
      loading: false,
    },
    prolonged: {
      data: undefined,
      metaData: undefined,
      loading: false,
      connectToUser: {
        showConnectConfirmModal: false,
      },
    },
  },
  bonusDeposit: {
    isPayment: false,
    loading: false,
  },
};

export const AdminSupportSlice = createSlice({
  name: 'adminSupport',
  initialState,
  reducers: {
    onOpenConnectModal(state, action: PayloadAction<number>) {
      state.troublePolls.prolonged.connectToUser.showConnectConfirmModal = true;
      state.troublePolls.prolonged.connectToUser.user_id = action.payload;
    },
    onCloseConnectModal(state) {
      state.troublePolls.prolonged.connectToUser =
        initialState.troublePolls.prolonged.connectToUser;
    },
    clearBonusDeposit(state) {
      state.bonusDeposit = initialState.bonusDeposit;
    },
    clearNotifyError(state) {
      state.payments.notPaid.notifyError = undefined;
      state.payments.notApproved.notifyError = undefined;
    },
  },
  extraReducers: builder => {
    //ClientList
    builder
      .addCase(clientsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(clientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.clients = action.payload;
      })
      .addCase(clientsList.rejected, (state, action) => {
        state.isLoading = false;
        const message = (action.payload as NetworkError).message;
        state.error = { ...(action.payload as NetworkError), message };
      });

    //getRespondentProfile
    builder
      .addCase(getRespondentProfile.pending, state => {
        state.respondent.profile.loading = true;
        state.respondent.profile.error = undefined;
      })
      .addCase(getRespondentProfile.fulfilled, (state, action) => {
        state.respondent.profile.loading = false;
        state.respondent.profile.error = undefined;
        state.respondent.profile.data = action.payload;
      })
      .addCase(getRespondentProfile.rejected, (state, action) => {
        state.respondent.profile.loading = false;
        const message = (action.payload as NetworkError).message;
        state.respondent.profile.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getRespondentTransactions
    builder
      .addCase(getRespondentTransactions.pending, state => {
        state.respondent.transactions.loading = true;
        state.respondent.transactions.error = undefined;
      })
      .addCase(getRespondentTransactions.fulfilled, (state, action) => {
        state.respondent.transactions.loading = false;
        state.respondent.transactions.error = undefined;
        state.respondent.transactions.data = action.payload.data;
        state.respondent.transactions.metaData = action.payload.meta;
      })
      .addCase(getRespondentTransactions.rejected, (state, action) => {
        state.respondent.transactions.loading = false;
        const message = (action.payload as NetworkError).message;
        state.respondent.transactions.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getRespondentResults
    builder
      .addCase(getRespondentResults.pending, state => {
        state.respondent.results.loading = true;
        state.respondent.results.error = undefined;
      })
      .addCase(getRespondentResults.fulfilled, (state, action) => {
        state.respondent.results.loading = false;
        state.respondent.results.error = undefined;
        state.respondent.results.data = action.payload.data;
        state.respondent.results.metaData = action.payload.meta;
      })
      .addCase(getRespondentResults.rejected, (state, action) => {
        state.respondent.results.loading = false;
        const message = (action.payload as NetworkError).message;
        state.respondent.results.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getRespondentComplaints
    builder
      .addCase(getRespondentComplaints.pending, state => {
        state.respondent.complaints.loading = true;
        state.respondent.complaints.error = undefined;
      })
      .addCase(getRespondentComplaints.fulfilled, (state, action) => {
        state.respondent.complaints.loading = false;
        state.respondent.complaints.error = undefined;
        state.respondent.complaints.data = action.payload.data;
        state.respondent.complaints.metaData = action.payload.meta;
      })
      .addCase(getRespondentComplaints.rejected, (state, action) => {
        state.respondent.complaints.loading = false;
        const message = (action.payload as NetworkError).message;
        state.respondent.complaints.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getNotApprovedTransactions
    builder
      .addCase(getNotApprovedTransactions.pending, state => {
        state.payments.notApproved.loading = true;
        state.payments.notApproved.error = undefined;
      })
      .addCase(getNotApprovedTransactions.fulfilled, (state, action) => {
        state.payments.notApproved.loading = false;
        state.payments.notApproved.error = undefined;
        state.payments.notApproved.data = action.payload.data;
        state.payments.notApproved.metaData = action.payload.meta;
      })
      .addCase(getNotApprovedTransactions.rejected, (state, action) => {
        state.payments.notApproved.loading = false;
        const message = (action.payload as NetworkError).message;
        state.payments.notApproved.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getNotPaidTransactions
    builder
      .addCase(getNotPaidTransactions.pending, state => {
        state.payments.notPaid.loading = true;
        state.payments.notPaid.error = undefined;
      })
      .addCase(getNotPaidTransactions.fulfilled, (state, action) => {
        state.payments.notPaid.loading = false;
        state.payments.notPaid.error = undefined;
        state.payments.notPaid.data = action.payload.data;
        state.payments.notPaid.metaData = action.payload.meta;
      })
      .addCase(getNotPaidTransactions.rejected, (state, action) => {
        state.payments.notPaid.loading = false;
        const message = (action.payload as NetworkError).message;
        state.payments.notPaid.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //fetchTransactionsRetry
    builder
      .addCase(fetchTransactionsRetry.pending, state => {
        state.payments.notPaid.loading = true;
        state.payments.notPaid.notifyError = undefined;
      })
      .addCase(fetchTransactionsRetry.fulfilled, (state, action) => {
        state.payments.notPaid.loading = false;
        state.payments.notPaid.notifyError = undefined;
        state.payments.notPaid.data = action.payload.data;
      })
      .addCase(fetchTransactionsRetry.rejected, (state, action) => {
        state.payments.notPaid.loading = false;
        const message = (action.payload as NetworkError).message;
        state.payments.notPaid.notifyError = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //fetchNotifyToModerator
    builder
      .addCase(fetchNotifyToModerator.pending, state => {
        state.payments.notPaid.loading = true;
        state.payments.notPaid.notifyError = undefined;
      })
      .addCase(fetchNotifyToModerator.fulfilled, (state, action) => {
        state.payments.notPaid.loading = false;
        state.payments.notPaid.notifyError = undefined;
        state.payments.notApproved.data = state.payments.notApproved.data?.map(
          transactions => {
            if (transactions.id === action.payload.data.id) {
              return action.payload.data;
            } else {
              return transactions;
            }
          },
        );
      })
      .addCase(fetchNotifyToModerator.rejected, (state, action) => {
        state.payments.notPaid.loading = false;
        const message = (action.payload as NetworkError).message;
        state.payments.notPaid.notifyError = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getTroublePollsNotApproved
    builder
      .addCase(getTroublePollsNotApproved.pending, state => {
        state.troublePolls.notApproved.loading = true;
        state.troublePolls.notApproved.error = undefined;
      })
      .addCase(getTroublePollsNotApproved.fulfilled, (state, action) => {
        state.troublePolls.notApproved.loading = false;
        state.troublePolls.notApproved.error = undefined;
        state.troublePolls.notApproved.data = action.payload.data;
        state.troublePolls.notApproved.metaData = action.payload.meta;
      })
      .addCase(getTroublePollsNotApproved.rejected, (state, action) => {
        state.troublePolls.notApproved.loading = false;
        const message = (action.payload as NetworkError).message;
        state.troublePolls.notApproved.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getTroublePollsProlonged
    builder
      .addCase(getTroublePollsProlonged.pending, state => {
        state.troublePolls.prolonged.loading = true;
        state.troublePolls.prolonged.error = undefined;
      })
      .addCase(getTroublePollsProlonged.fulfilled, (state, action) => {
        state.troublePolls.prolonged.loading = false;
        state.troublePolls.prolonged.error = undefined;
        state.troublePolls.prolonged.data = action.payload.data;
        state.troublePolls.prolonged.metaData = action.payload.meta;
      })
      .addCase(getTroublePollsProlonged.rejected, (state, action) => {
        state.troublePolls.prolonged.loading = false;
        const message = (action.payload as NetworkError).message;
        state.troublePolls.prolonged.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //getAccess
    builder
      .addCase(getAccess.pending, state => {
        state.access.isLoading = true;
        state.access.error = null;
      })
      .addCase(getAccess.fulfilled, (state, action) => {
        state.access.isLoading = false;
        state.access.error = null;
        state.access.user = action.payload;
      })
      .addCase(getAccess.rejected, (state, action) => {
        state.access.isLoading = false;
        const message = (action.payload as NetworkError).message;
        state.access.error = { ...(action.payload as NetworkError), message };
      });

    //fetchBonusDeposit
    builder
      .addCase(fetchBonusDeposit.pending, state => {
        state.bonusDeposit.loading = true;
        state.bonusDeposit.error = undefined;
      })
      .addCase(fetchBonusDeposit.fulfilled, state => {
        state.bonusDeposit.loading = false;
        state.bonusDeposit.error = undefined;
        state.bonusDeposit.isPayment = true;
      })
      .addCase(fetchBonusDeposit.rejected, (state, action) => {
        state.bonusDeposit.loading = false;
        const message = (action.payload as NetworkError).message;
        state.bonusDeposit.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });
  },
});

export const { actions: AdminSupportActions } = AdminSupportSlice;
export const { reducer: AdminSupportReducer } = AdminSupportSlice;
