import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { TSupportMessageData } from 'features/ContactSupport/model/types/userSupportSchema';
import { TSendMessageToRespondentsRequest } from 'features/SendMessageToRespondents';
import {
  Client,
  TMetaData,
  TRespondentProfile,
  TRespondentsComplaintsData,
  TRespondentsResultsData,
  TRespondentTransactionsRequest,
  TTroublePollsRequest,
  TTransactionsData,
  TTroublePoll,
} from 'features/AdminSupport/model/types/adminSupportSchema';
import { CancelToken } from 'axios';
import { TPoll } from 'pages/cabinet/store/types';

class SupportModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка клиентов
  async getClinetsList(data: {
    limit?: number;
    query?: string;
    cancelToken?: CancelToken;
  }): Promise<{
    data: Client[];
  }> {
    const { limit = 100, query, cancelToken } = data;
    return this.send('get', `/supports/clients`, {
      params: { limit, query },
      cancelToken,
    });
  }

  //Получение списка респондентов
  async getRespondentsList(data: {
    limit?: number;
    query?: string;
    cancelToken?: CancelToken;
  }): Promise<{
    data: Client[];
  }> {
    const { limit = 100, query, cancelToken } = data;
    return this.send('get', `/supports/respondents`, {
      params: { limit, query },
      cancelToken,
    });
  }

  //Получение профиля респондента
  async getRespondentProfile(data: { id: string }): Promise<{
    data: TRespondentProfile;
  }> {
    const { id } = data;
    return this.send('get', `/supports/respondents/${id}/profile`);
  }

  //Получение транзакций респондента

  async getRespondentsTransactions(
    data: TRespondentTransactionsRequest,
  ): Promise<{
    data: TTransactionsData[];
    meta: TMetaData;
  }> {
    return this.send('get', `/supports/respondents/transactions`, {
      params: { ...data },
    });
  }

  async getNotApprovedTransactions(
    data: TRespondentTransactionsRequest,
  ): Promise<{
    data: TTransactionsData[];
    meta: TMetaData;
  }> {
    return this.send('get', `/supports/respondents/transactions`, {
      params: { ...data },
    });
  }

  async getNotPaidTransactions(data: TRespondentTransactionsRequest): Promise<{
    data: TTransactionsData[];
    meta: TMetaData;
  }> {
    return this.send('get', `/supports/respondents/transactions`, {
      params: { ...data },
    });
  }

  // Получение проблемных тестов

  async getTroublePolls(data: TTroublePollsRequest): Promise<{
    data: TTroublePoll[];
    meta: TMetaData;
  }> {
    const { type, ...rest } = data;
    return this.send('get', `/supports/polls/trouble/${type}`, {
      params: { ...rest },
    });
  }

  //Получение ответов респондента

  async getRespondentsResults(data: TRespondentTransactionsRequest): Promise<{
    data: TRespondentsResultsData[];
    meta: TMetaData;
  }> {
    const { user_id, ...rest } = data;
    return this.send('get', `/supports/respondents/${user_id}/results`, {
      params: { ...rest },
    });
  }

  //Получение жалоб респондента

  async getRespondentsComplaints(
    data: TRespondentTransactionsRequest,
  ): Promise<{
    data: TRespondentsComplaintsData[];
    meta: TMetaData;
  }> {
    const { user_id, ...rest } = data;
    return this.send('get', `/supports/respondents/${user_id}/complaints`, {
      params: { ...rest },
    });
  }

  //Получение списка промокодов
  async getPromocodes(data: { query?: string }): Promise<TAxios> {
    return this.send('get', `/supports/promocodes`, { ...data });
  }

  //POST
  //Получить лоступ к ЛК пользователя
  async getAccess(id: number): Promise<TAxios> {
    return this.send('post', `/supports/access`, { id });
  }

  // Отправить сообщение в тех.поддержку
  async postUserSupport(data: TSupportMessageData): Promise<TAxios> {
    return this.send('post', `/supports/message`, { ...data });
  }

  // Отправить сообщение респондентам
  async postMessageToRespondents(
    data: TSendMessageToRespondentsRequest,
  ): Promise<TAxios> {
    return this.send('post', `/supports/message-to-respondents`, { ...data });
  }

  // Отправить напоминание модератору
  async postNotifyToModerator(data: { id: number }): Promise<TAxios> {
    return this.send(
      'post',
      `/supports/respondents/transactions/${data.id}/notify`,
    );
  }

  // Повторное действие с платежом
  async postTransactionRetry(data: {
    id: number;
    page: string;
    return_to_balance?: boolean;
  }): Promise<TAxios> {
    return this.send('post', `/supports/respondents/transactions/retry`, {
      ...data,
      limit: 30,
    });
  }

  //Зачислить бонус
  async postBonusDeposit(data: {
    id: number;
    sum: number;
    comment: string;
  }): Promise<TAxios> {
    return this.send('post', `/supports/clients/bonus`, { ...data });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const supportModel = new SupportModel(baseUrl);

export default supportModel;
