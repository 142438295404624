import { getAuthOzonUserData } from 'shared/helpers/auth';
import { TAuthState } from './types';

export const initialPromocode = {
  address: null,
  bank_acc: null,
  bank_bic: null,
  bank_cor_acc: null,
  bank_name: null,
  code: null,
  id: null,
  inn: null,
  name: null,
  passport_date: null,
  passport_issued: null,
  passport_num: null,
  referral_bonus: null,
  snils: null,
  users: null,
  type: null,
  sum: null,
};

const initialState: TAuthState = {
  isAuth: false,
  user: {
    response: null,
    error: null,
    loading: false,
  },
  auth: {
    response: null,
    error: null,
    loading: false,
  },
  bitrixAuth: {
    isBitrixAuth: false,
  },
  ozonAuth: {
    isOzonAuth: false,
    isLocalStorageOzonAuth: !!getAuthOzonUserData(),
  },
  sms: {
    response: {
      message: null,
      registered: false,
    },
    error: null,
    loading: false,
  },
  partner: {
    data: initialPromocode,
    initialData: initialPromocode,
    loading: false,
    isNewPArtnerCreated: false,
  },
};

export default initialState;
