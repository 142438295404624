import { Modal } from 'shared/components/modal/modal';
import { Suspense } from 'react';
import { RespondentSupportForm } from './form/respondentSupporForm.async';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RespondentSupportModal = (props: IProps) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Suspense fallback={''}>
        <RespondentSupportForm onSuccess={onClose} />
      </Suspense>
    </Modal>
  );
};
