import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
import {
  TRespondentTransactionsRequest,
  TTransactionsData,
} from '../../types/adminSupportSchema';

export const getRespondentTransactions = createAsyncThunk(
  'support/getRespondentTransactions',
  async (data: TRespondentTransactionsRequest, thunkApi) => {
    try {
      const response = await supportModel.getRespondentsTransactions(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const getNotApprovedTransactions = createAsyncThunk(
  'support/getNotApprovedTransactions',
  async (data: TRespondentTransactionsRequest, thunkApi) => {
    try {
      const response = await supportModel.getNotApprovedTransactions(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const getNotPaidTransactions = createAsyncThunk(
  'support/getNotPaidTransactions',
  async (data: TRespondentTransactionsRequest, thunkApi) => {
    try {
      const response = await supportModel.getNotPaidTransactions(data);

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const fetchNotifyToModerator = createAsyncThunk(
  'support/fetchNotifyToModerator',
  async (data: { id: number }, thunkApi) => {
    try {
      const response: { data: TTransactionsData } =
        await supportModel.postNotifyToModerator(data);
      return response;
      // throw new Error('test');
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const fetchTransactionsRetry = createAsyncThunk(
  'support/fetchTransactionsRetry',
  async (
    data: {
      id: number;
      page: string;
      return_to_balance?: boolean;
    },
    thunkApi,
  ) => {
    try {
      const response = await supportModel.postTransactionRetry(data);
      return response;
      // throw new Error('test');
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const fetchBonusDeposit = createAsyncThunk(
  'support/fetchBonusDeposit',
  async (data: { id: number; sum: number; comment: string }, thunkApi) => {
    try {
      const response = await supportModel.postBonusDeposit(data);
      return response;
      // setTimeout(() => {
      //   return {
      //     success: true,
      //   };
      // }, 1000);
      // throw new Error('test');
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

// postNotifyToModerator
